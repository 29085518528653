import * as React from "react"
import Seo from "../components/Seo/Seo"
import FourOhFour from "../components/404/404"

const NotFoundPage = () => (
  <React.Fragment>
    <Seo title="404: Not found" />
    <FourOhFour />
  </React.Fragment>
)

export default NotFoundPage
