import { createStyles, Theme } from "@material-ui/core"

const fourOhFourStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: "6.5rem auto"
    },

  })

export default fourOhFourStyles