import * as React from "react"
import fourOhFourStyles from "./404.styles"
import { IProps } from "./404.types"
import { Container, Grid, Typography, withStyles } from "@material-ui/core"

const FourOhFour = ({ classes }: IProps) => (
  <Grid className={classes.root}>
    <Container>
      <Typography variant="h1" color="textPrimary">404</Typography>
      <Typography variant="h3" color="textPrimary">That doesn't seem to be here</Typography>
    </Container>
  </Grid>
)

export default withStyles(fourOhFourStyles)(FourOhFour)
